/* *~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~ */
/* App */
/* *~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~ */

.App {
	height: 100%;
	/* padding-left: 1%; Center card horizontally with scroll-snap */
}

button {
	-webkit-tap-highlight-color: transparent; /* No button flash on tap */
}

/* *~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~ */
/* Toolbar */
/* *~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~ */

#toolbar {
	--toolbar-height: min(20vw, 70px); /* 100/numberOfToolbarItems vw */
	--toolbar-hide-duration: 1s;
	box-sizing: border-box; /* height includes border */
	position: absolute;
	z-index: 5;
	background-color: aliceblue;
	width: 100%;
	height: var(--toolbar-height);
	left: 0; /* Ignore parent's left padding */
	border-bottom: 0.5px solid black;
	transition-duration: var(--toolbar-hide-duration);
}
#toolbar.hide {
	transform: translateY(calc(var(--toolbar-height) * -1));
}

.toolbar-item { 
	width: var(--toolbar-height);
	background-color: transparent;
	border: none;
	opacity: 0.15;
	display: inline-block;
	padding: 0;
	height: 100%;
}

.toolbar-item > svg {
	padding: 20%;
}

#toolbar-toggler {
	position: absolute;
	right: 0;
	transition-duration: var(--toolbar-hide-duration);
}
#toolbar-toggler.prevent-hide {
	transform: translateY(calc(var(--toolbar-height))) rotate(180deg);
}

#font-size-picker {
	overflow-y: scroll;
	scroll-snap-type: y mandatory;
	scrollbar-width: none; /* firefox only */
	line-height: var(--toolbar-height); /* vertically align text */
}
#font-size-picker::-webkit-scrollbar { /* chrome & safari only */
	display: none; 
}

#font-size-picker > .font-size-option {
	text-align: center;
	scroll-snap-align: start;
	font-size: calc(var(--toolbar-height) / 2.5); /* fits "XL" */
}

/* *~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~ */
/* Deck & Cards */
/* *~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~ */

#deck {
	height: 100%;
	overflow: scroll hidden; /* x | y */
	perspective: 1000px; /* 3d card flip */
	scroll-snap-type: x mandatory;
	scrollbar-width: none; /* firefox only */
	white-space: nowrap; /* prevent cards from wrapping */
}
#deck::-webkit-scrollbar { /* chrome & safari only */
	display: none; 
}

.card {
	--card-margin: 10px;
	background-color: white;
	border: 1px solid black;	
	box-shadow: 2px 2px 5px black;
	box-sizing: border-box; /* width & height include padding & border */
	display: inline-block; /* no newline after card */
	height: calc(100% - 2 * var(--card-margin));
	margin: var(--card-margin);
	overflow-wrap: break-word; /* break long words if needed */
	position: relative; /* to position absolute card-front & card-back */
	scroll-margin: var(--card-margin);
	scroll-snap-align: start; /* scroll snaps to start of this element */
	transform-style: preserve-3d;
	transition: none; /* set in JavaScript */
	white-space: normal; /* don't inherit "nowrap" from deck */
	width: calc(100% - 2 * var(--card-margin));
	z-index: 2;
}
.card.flipped {
	transform: rotateY(180deg); 
}

.card-front, .card-back {
	backface-visibility: hidden;
	height: fit-content;
	inset: 0;     /* Center vertically & horizontally */
	margin: auto; /* Center vertically & horizontally */
	mask-image: linear-gradient(transparent 0%, black 5%, black 95%, transparent 100%); /* fade effect for top and bottom */
	max-height: 100%;
	max-width: 80%;
	overflow-y: scroll; /* Breaks backface-visibility if defined on parent */ 
	position: absolute;
	transform: rotateX(0deg); /* Needed to hide backface in Firefox */
	width: fit-content;
}

.card-front {
	text-align: center;
	z-index: 3;
}

.card-back {
	transform: rotateY(-180deg);
	z-index: 1;
}

#card-index-display {
	background-color: transparent;
	border: none;
	color: rgba(0, 0, 0, 0.2);
	font-size: 30px;
	inset: auto 0 25px; /* top left/right bottom */
	margin: auto; 		/* center horizontally */
	position: absolute;
	transition-duration: 1s;
	width: fit-content;
}
#card-index-display.hide {
	opacity: 0;
}

/* *~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~ */
/* Markdown */
/* *~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~ */

.react-markdown img {
	max-width: 100%;
}

.katex-display { 
	overflow-x: scroll 
}

.katex-display > .katex { /* Allow line breaks in display-mode */
	white-space: normal !important;
	margin: 0.5em 0; 
}

.katex-display > .base { 
	margin: 0.25em 0 /* Add space between broken lines */
}